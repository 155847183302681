import React, { Fragment } from 'react';
import { Link } from '@inertiajs/react';
import { MapPin, ChevronDown } from 'lucide-react';
import { Button } from "@/Components/ui/button";
import { Menu, Transition } from '@headlessui/react';

interface Field {
    name: string;
    link: string;
}

interface HeroProps {
    fields: Field[];
}

const Hero: React.FC<HeroProps> = ({ fields }) => {
    return (
        <div className="relative h-[30rem] block w-full">
            {/* Background Image */}
            <img
                src="/images/bg.jpg"
                className="absolute inset-0 w-full h-full object-cover"
                alt="Background"
            />
            {/* Overlay for better text visibility */}
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            {/* Content */}
            <div className="relative z-10 h-full flex flex-col items-center justify-center text-center text-white">
                <h1 className="text-4xl w-2/4 md:text-6xl font-bold mb-8">
                    Safe, Secure and Exclusive Doggy Exercise Fields.
                </h1>
                <Link href="/locations">
                    <Button size="lg" className="text-xl mt-6 hover:bg-dark bg-brand px-8 py-8">
                        Book Now
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Hero;