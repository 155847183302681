import React from 'react';
import { StarIcon } from 'lucide-react';

interface TestimonialProps {
    name: string;
    role: string;
    content: string;
    rating: number;
}

const Testimonial: React.FC<TestimonialProps> = ({ name, role, content, rating }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex items-center mb-4">
            {[...Array(5)].map((_, i) => (
                <StarIcon
                    key={i}
                    className={`w-5 h-5 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                    fill={i < rating ? 'currentColor' : 'none'}
                />
            ))}
        </div>
        <p className="text-gray-600 mb-4">{content}</p>
        <div className="font-semibold">{name}</div>
        <div className="text-sm text-gray-500">{role}</div>
    </div>
);

const Testimonials: React.FC = () => {
    const testimonials = [
        {
            name: "Polly-Anna",
            role: "Shenley",
            content: "We enjoyed our first walk at Walkies today and I cannot recommend it highly enough! We have an 11-month-old lively Labrador whose recall is pretty hit and miss making it difficult to let him off the lead in open spaces. It was so lovely to see him run freely with no concern he'll escape. We will be visiting again very soon!",
            rating: 5
        },
        {
            name: "Brian",
            role: "Ridge",
            content: "Easy Booking, Easy Access and Secure parking. Its minutes off the motorway. A superb facility to let my dogs free for ultimate fun with peace of mind. Ideal solution for someone like me who isn’t as mobile as I used to be!  Next time I will intend to meet a friend there whilst the dogs play. Look forward to visiting again. ",
            rating: 5
        },
        {
            name: "Sally",
            role: "South Mimms",
            content: "We loved visiting today, it was Alfie's first time off the lead, and it was so nice for both of us - he wasn't distracted and I wasn't stressed! Win win! Great facility with easy booking and secure parking. You can even sit under cover if the weather is bad, whilst your dog explores. Would recommend to everyone!",
            rating: 5
        }
    ];

    return (
        <section className="py-16 px-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
                    What Our Customers Say
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <Testimonial
                            key={index}
                            name={testimonial.name}
                            role={testimonial.role}
                            content={testimonial.content}
                            rating={testimonial.rating}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;