import React from 'react';
import {
    Shield,
    CalendarDays,
    Heart,
    PawPrint,
    Bone,
    Award,
    Sun,
    Users,
    Map,
    Sprout,
    LucideIcon
} from 'lucide-react';
import Feature from './Feature';


const WhyUs: React.FC = () => {
    const features = [
        { Icon: Shield, title: "Exclusive & secure exercise", description: "Exclusive use of secure and safe exercise field, perfect for dogs of all shapes and sizes, with or without friends. " },
        { Icon: CalendarDays, title: "Easy booking", description: "Easy online booking with mobile payment and digital wallet pay service, means you can book on the go." },
        { Icon: Bone, title: "Let Loose", description: "Worried about letting your doggy off the lead? Here they can follow their nose and burn excess energy." },
        { Icon: PawPrint, title: "For all Doggys", description: "Whether your doggy is nervous, young, recovering or unsociable, Walkies is the ideal solution for you." },
        { Icon: Map, title: "Multiple Locations", description: "We have Walkies fields all over the south east so you are never too far from a safe, secure dog walk." },
        { Icon: Award, title: "Enhance your Training", description: "Recall, retrieve or even some tricks off the lead. Gain confidence and avoid losing sight." },
    ];

    return (
        <section className="py-16 px-16 bg-gray-50">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
                    Why Choose Our Doggy Exercise Fields?
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <Feature
                            key={index}
                            Icon={feature.Icon}
                            title={feature.title}
                            description={feature.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyUs;